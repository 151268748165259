import { ProcessMap } from "../../models/process-map.model";
import { stateMap } from "../../state.map";

export class B2bCenterStateMap {

    static pageStateMap: { [key: string]: ProcessMap } = {
        ACTIVE: {
            state: 'ACTIVE',
            label: 'Common.active',
            status: 'success',
            outline: false,
            nextState: 'INACTIVE',
            nextStateLabel: 'Common.changeToInactive',
            confirmText: 'Common.inactiveConfirm',
            responseTitle: 'Common.inactivated',
            responseText: 'Common.inactiveSuccess',
        },
        INACTIVE: {
            state: 'INACTIVE',
            label: 'Common.inactive',
            status: 'danger',
            outline: true,
            nextState: 'ACTIVE',
            nextStateLabel: 'Common.active',
            confirmText: 'Common.activeConfirm',
            responseTitle: 'Common.activated',
            responseText: 'Common.activeSuccess',
        },
        NOTJUSTACTIVE: {
            state: 'NOTJUSTACTIVE',
            label: 'Common.notjustactive',
            status: 'danger',
            outline: false,
            nextState: 'ACTIVE',
            nextStateLabel: 'Common.active',
            confirmText: 'Common.activeConfirm',
            responseTitle: 'Common.activated',
            responseText: 'Common.activeSuccess',
        },
        "": {
            state: 'NOTJUSTACTIVE',
            label: 'Common.notjustactive',
            status: 'danger',
            outline: false,
            nextState: 'ACTIVE',
            nextStateLabel: 'Common.active',
            confirmText: 'Common.activeConfirm',
            responseTitle: 'Common.activated',
            responseText: 'Common.activeSuccess',
        },
    };



}
