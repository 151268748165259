import { Routes } from "@angular/router";
import { AuthGuardService } from "../../services/auth-guard.service";
import { B2bCenterPageListComponent } from "./page/b2b-center-page-list/b2b-center-page-list.component";

export const b2bCenterRoutes: Routes = [
  {
    path: 'b2b-center/page/list',
    canActivate: [AuthGuardService],
    component: B2bCenterPageListComponent,
    data: {
      reuse: true,
    },
  },
];